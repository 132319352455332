// tag::RDX-enum-presence-action[]
export enum PresenceActionType {
  HERE_NOW_COMMAND = 'pubnub/HERE_NOW_COMMAND',
  PRESENCE_STATE_COMMAND = 'pubnub/PRESENCE_STATE_COMMAND',
  FETCHING_HERE_NOW = 'pubnub/FETCHING_HERE_NOW',
  HERE_NOW_RETRIEVED = 'pubnub/HERE_NOW_RETRIEVED',
  ERROR_FETCHING_HERE_NOW = 'pubnub/ERROR_FETCHING_HERE_NOW',
  FETCHING_PRESENCE_STATE = 'pubnub/FETCHING_PRESENCE_STATE',
  PRESENCE_STATE_RETRIEVED = 'pubnub/PRESENCE_STATE_RETRIEVED',
  ERROR_FETCHING_PRESENCE_STATE = 'pubnub/ERROR_FETCHING_PRESENCE_STATE',
  JOIN_EVENT = 'pubnub/JOIN_EVENT',
  LEAVE_EVENT = 'pubnub/LEAVE_EVENT',
  TIMEOUT_EVENT = 'pubnub/TIMEOUT_EVENT',
  STATE_CHANGE_EVENT = 'pubnub/STATE_CHANGE_EVENT',
}
// end::RDX-enum-presence-action[]
