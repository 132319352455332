// tag::RDX-enum-space-action[]
export enum SpaceActionType {
  FETCH_SPACES_COMMAND = 'pubnub/FETCH_SPACES_COMMAND',
  FETCH_SPACE_BY_ID_COMMAND = 'pubnub/FETCH_SPACE_BY_ID_COMMAND',
  CREATE_SPACE_COMMAND = 'pubnub/CREATE_SPACE_COMMAND',
  UPDATE_SPACE_COMMAND = 'pubnub/UPDATE_SPACE_COMMAND',
  DELETE_SPACE_COMMAND = 'pubnub/DELETE_SPACE_COMMAND',
  UPDATING_SPACE = 'pubnub/UPDATING_SPACE',
  SPACE_UPDATED = 'pubnub/SPACE_UPDATED',
  ERROR_UPDATING_SPACE = 'pubnub/ERROR_UPDATING_SPACE',
  DELETING_SPACE = 'pubnub/DELETING_SPACE',
  SPACE_DELETED = 'pubnub/SPACE_DELETED',
  ERROR_DELETING_SPACE = 'pubnub/ERROR_DELETING_SPACE',
  CREATING_SPACE = 'pubnub/CREATING_SPACE',
  SPACE_CREATED = 'pubnub/SPACE_CREATED',
  ERROR_CREATING_SPACE = 'pubnub/ERROR_CREATING_SPACE',
  FETCHING_SPACES = 'pubnub/FETCHING_SPACES',
  SPACES_RETRIEVED = 'pubnub/SPACES_RETRIEVED',
  ERROR_FETCHING_SPACES = 'pubnub/ERROR_FETCHING_SPACES',
  FETCHING_SPACE_BY_ID = 'pubnub/FETCHING_SPACE_BY_ID',
  SPACE_RETRIEVED = 'pubnub/SPACE_RETRIEVED',
  ERROR_FETCHING_SPACE_BY_ID = 'pubnub/ERROR_FETCHING_SPACE_BY_ID',
  SPACE_UPDATED_EVENT = 'pubnub/SPACE_UPDATED_EVENT',
  SPACE_DELETED_EVENT = 'pubnub/SPACE_DELETED_EVENT',
}
// end::RDX-enum-space-action[]
