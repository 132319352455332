// tag::RDX-enum-memberships-action[]
export enum MembershipActionType {
  FETCH_MEMBERSHIP_COMMAND = 'pubnub/FETCH_MEMBERSHIP_COMMAND',
  JOIN_SPACES_COMMAND = 'pubnub/JOIN_SPACES_COMMAND',
  LEAVE_SPACES_COMMAND = 'pubnub/LEAVE_SPACES_COMMAND',
  UPDATE_MEMBERSHIP_COMMAND = 'pubnub/UPDATE_MEMBERSHIP_COMMAND',
  USER_ADDED_TO_SPACE_EVENT = 'pubnub/USER_ADDED_TO_SPACE_EVENT',
  USER_REMOVED_FROM_SPACE_EVENT = 'pubnub/USER_REMOVED_FROM_SPACE_EVENT',
  USER_MEMBERSHIP_UPDATED_ON_SPACE_EVENT = 'pubnub/USER_MEMBERSHIP_UPDATED_ON_SPACE_EVENT',
  UPDATING_MEMBERSHIP = 'pubnub/UPDATING_MEMBERSHIP',
  MEMBERSHIP_UPDATED = 'pubnub/MEMBERSHIP_UPDATED',
  ERROR_UPDATING_MEMBERSHIP = 'pubnub/ERROR_UPDATING_MEMBERSHIP',
  FETCHING_MEMBERSHIP = 'pubnub/FETCHING_MEMBERSHIP',
  MEMBERSHIP_RETRIEVED = 'pubnub/MEMBERSHIP_RETRIEVED',
  ERROR_FETCHING_MEMBERSHIP = 'pubnub/ERROR_FETCHING_MEMBERSHIP',
  JOINING_SPACES = 'pubnub/JOINING_SPACES',
  SPACES_JOINED = 'pubnub/SPACES_JOINED',
  ERROR_JOINING_SPACES = 'pubnub/ERROR_JOINING_SPACES',
  LEAVING_SPACES = 'pubnub/LEAVING_SPACES',
  SPACES_LEFT = 'pubnub/SPACES_LEFT',
  ERROR_LEAVING_SPACES = 'pubnub/ERROR_LEAVING_SPACES',
}
// end::RDX-enum-memberships-action[]
