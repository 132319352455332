// tag::RDX-enum-memmber-action[]
export enum MembersActionType {
  FETCH_MEMBERS_COMMAND = 'pubnub/FETCH_MEMBERS_COMMAND',
  UPDATE_MEMBERS_COMMAND = 'pubnub/UPDATE_MEMBERS_COMMAND',
  ADD_MEMBERS_COMMAND = 'pubnub/ADD_MEMBERS_COMMAND',
  REMOVE_MEMBERS_COMMAND = 'pubnub/REMOVE_MEMBERS_COMMAND',
  FETCHING_MEMBERS = 'pubnub/FETCHING_MEMBERS',
  MEMBERS_RETRIEVED = 'pubnub/MEMBERS_RETRIEVED',
  ERROR_FETCHING_MEMBERS = 'pubnub/ERROR_FETCHING_MEMBERS',
  UPDATING_MEMBERS = 'pubnub/UPDATING_MEMBERS',
  MEMBERS_UPDATED = 'pubnub/MEMBERS_UPDATED',
  ERROR_UPDATING_MEMBERS = 'pubnub/ERROR_UPDATING_MEMBERS',
  ADDING_MEMBERS = 'pubnub/ADDING_MEMBERS',
  MEMBERS_ADDED = 'pubnub/MEMBERS_ADDED',
  ERROR_ADDING_MEMBERS = 'pubnub/ERROR_ADDING_MEMBERS',
  REMOVING_MEMBERS = 'pubnub/REMOVING_MEMBERS',
  MEMBERS_REMOVED = 'pubnub/MEMBERS_REMOVED',
  ERROR_REMOVING_MEMBERS = 'pubnub/ERROR_REMOVING_MEMBERS',
}
// end::RDX-enum-memmber-action[]
