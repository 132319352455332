// tag::RDX-enum-user-action[]
export enum UserActionType {
  FETCH_USERS_COMMAND = 'pubnub/FETCH_USERS_COMMAND',
  FETCH_USER_BY_ID_COMMAND = 'pubnub/FETCH_USER_BY_ID_COMMAND',
  CREATE_USER_COMMAND = 'pubnub/CREATE_USER_COMMAND',
  UPDATE_USER_COMMAND = 'pubnub/UPDATE_USER_COMMAND',
  DELETE_USER_COMMAND = 'pubnub/DELETE_USER_COMMAND',
  UPDATING_USER = 'pubnub/UPDATING_USER',
  USER_UPDATED = 'pubnub/USER_UPDATED',
  ERROR_UPDATING_USER = 'pubnub/ERROR_UPDATING_USER',
  DELETING_USER = 'pubnub/DELETING_USER',
  USER_DELETED = 'pubnub/USER_DELETED',
  ERROR_DELETING_USER = 'pubnub/ERROR_DELETING_USER',
  CREATING_USER = 'pubnub/CREATING_USER',
  USER_CREATED = 'pubnub/USER_CREATED',
  ERROR_CREATING_USER = 'pubnub/ERROR_CREATING_USER',
  FETCHING_USERS = 'pubnub/FETCHING_USERS',
  USERS_RETRIEVED = 'pubnub/USERS_RETRIEVED',
  ERROR_FETCHING_USERS = 'pubnub/ERROR_FETCHING_USERS',
  FETCHING_USER_BY_ID = 'pubnub/FETCHING_USER_BY_ID',
  USER_RETRIEVED = 'pubnub/USER_RETRIEVED',
  ERROR_FETCHING_USER_BY_ID = 'pubnub/ERROR_FETCHING_USER_BY_ID',
  USER_UPDATED_EVENT = 'pubnub/USER_UPDATED_EVENT',
  USER_DELETED_EVENT = 'pubnub/USER_DELETED_EVENT',
}
// end::RDX-enum-user-action[]
